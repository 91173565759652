/* Set default font-family */
.App .ql-editor {
  font-family: 'Roboto', sans-serif;
}
/* Set content for font-families */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='notoserif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='notoserif']::before {
  content: 'Noto Serif';
  font-family: 'NotoSerif', serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='montserrat']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='montserrat']::before {
  content: 'Montserrat';
  font-family: 'Montserrat', sans-serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='roboto']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='roboto']::before {
  content: 'Roboto';
  font-family: 'Roboto', sans-serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='opensans']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='opensans']::before {
  content: 'Open Sans';
  font-family: 'OpenSans', sans-serif;
}

/* Set content font-families */
.ql-font {
  font-family: 'Roboto', sans-serif;
}

.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}
.ql-font-montserrat {
  font-family: 'Montserrat', sans-serif;
}
.ql-font-noto-serif {
  font-family: 'NotoSerif', sans-serif;
}
.ql-font-open-sans {
  font-family: 'OpenSans', sans-serif;
}
